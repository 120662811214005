"use strict";

import angular from "angular";

onRun.$inject = ["$cookies", "cookiesService","$rootScope"];
function onRun($cookies, cookiesService, $rootScope) {
  //seteo de variables root para dar soporte a parámetros de leads por url
  const urlParams = new URLSearchParams(window.location.search);
  $rootScope.utm_campaign = urlParams.get("utm_campaign")??"Desconocido";
  $rootScope.utm_medium = urlParams.get("utm_medium")??"Desconocido";
  $rootScope.utm_source = urlParams.get("utm_source")??"Desconocido";
  $rootScope.utm_id = urlParams.get("utm_id") ?? "Desconocido";
  $rootScope.PD = urlParams.get("PD") ?? "Desconocido";
  $rootScope.TR = urlParams.get("TR") ?? "Desconocido";
  $rootScope.AdID = urlParams.get("AdID") ?? "Desconocido";
  $rootScope.utm_term = urlParams.get("utm_term") ?? "Desconocido";
  $rootScope.ST = urlParams.get("ST") ?? "Desconocido";
  $rootScope.utm_content = urlParams.get("utm_content") ?? "Desconocido";
  $rootScope.gclid = urlParams.get("gclid") ?? "Desconocido";
  $rootScope.fbclid =urlParams.get("fbclid") ?? "Desconocido";

  constructor();

  function constructor() {
    var date = new Date();
    date.setTime(date.getTime() + (20* 60 * 1000));

    let cookies = $cookies.get("sweno.consent");
    const consent = cookies ? angular.fromJson(cookies) : undefined;
    if (consent) {
      cookiesService.setShow(false);
      if (consent.statistics) {
        cookiesService.startRegisteringEvents(consent.analytics);
        cookiesService.startRegisteringEventsB(consent.comport);        
      }
    }else{
      cookiesService.startRegisteringEvents(false);
      cookiesService.startRegisteringEventsB(false);
    }
  }
}

export { onRun as COOKIES_RUN };