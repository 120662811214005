// config.http.js
/* globals apiKey, withTrace */
"use strict"

function configHttp($httpProvider) {
    $httpProvider.interceptors.push(interceptor);
}

configHttp.$inject = ["$httpProvider"];

function interceptor() {
    return {
        request: function(request) {
            request.headers['Ocp-Apim-Subscription-Key'] = apiKey;
            request.headers['Ocp-Apim-Trace'] = withTrace;
            return request;
        }
    };
}

interceptor.$inject = [];

export { configHttp };
