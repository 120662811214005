// cookies-dialog.controller.js
"use strict";

class cookiesDialogController {
    constructor() {
        this.editando = false;
    }

    $onInit() {     
        this.analytics = false;
        this.comport = false;
    }

    onAccept() {
        this.accept({analytics:this.analytics, comport: this.comport});
    }

    onAcceptAll() {
        this.analytics = true;
        this.comport = true;
        this.accept({analytics:this.analytics, comport:this.comport});
    }
}

cookiesDialogController.$inject = [];

export { cookiesDialogController as COOKIES_DIALOG_CONTROLLER };
