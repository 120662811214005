// cookies.service.js
/* globals angular */
"use strict";

class cookiesService {
  constructor($transitions, gtag, $cookies) {
    this.$transitions = $transitions;
    this.gtag = gtag;
    this.$cookies = $cookies;
    this.consentGiven = true;
  }

  startRegisteringEvents(consent) {
    //gtag
    this.gtag("js", new Date());
    this.gtag("config", "G-KWRZ4BFZMS");

    if (consent) {
      this.gtag("consent", "update", {
        'ad_storage': 'granted',
        'analytics_storage': 'granted',
        'functionality_storage': 'granted',
        'personalization_storage': 'granted',
        'security_storage': 'granted',
        'wait_for_update': 500
      });
      this.$transitions.onSuccess(
        {},
        function (transition) {
          if (transition._options.source === "url") {
            this.gtag("config", "G-KWRZ4BFZMS", {
              page_path: transition.to().url,
            });
          }
        }.bind(this)
      );
    } else {
      this.gtag("consent", "update", {
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
        'functionality_storage': 'denied',
        'personalization_storage': 'denied',
        'security_storage': 'denied',
        'wait_for_update': 500
      });
    }
  }

  startRegisteringEventsB(consent) {
    //tag manager
    this.gtag("js", new Date());
    this.gtag("config", "GTM-W85HL4D");

    if (consent) {
      this.gtag("consent", "update", {
        'ad_storage': 'granted',
        'analytics_storage': 'granted',
        'functionality_storage': 'granted',
        'personalization_storage': 'granted',
        'security_storage': 'granted',
        'wait_for_update': 500
      });
      this.$transitions.onSuccess(
        {},
        function (transition) {
          if (transition._options.source === "url") {
            this.gtag("config", "GTM-W85HL4D", {
              page_path: transition.to().url,
            });
          }
        }.bind(this)
      );
    } else {
      this.gtag("consent", "update", {
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
        'functionality_storage': 'denied',
        'personalization_storage': 'denied',
        'security_storage': 'denied',
        'wait_for_update': 500
      });
    }
  }

  storePreferences(consent) {
    this.$cookies.put("sweno.consent", angular.toJson(consent), {
      samesite: "lax",
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 2)),
    });
  }

  show() {
    return this.consentGiven;
  }

  setShow(value) {
    this.consentGiven = value;
  }
}

cookiesService.$inject = ["$transitions", "gtag", "$cookies"];

export { cookiesService as COOKIES_SERVICE };
