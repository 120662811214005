// app.routing.js

"use strict";

// import { home, tarifas, contacto, centroAyuda, quienesSomos, legales, legalesDetalle  } from "./app.states";
import { home  } from "./app.states";

appRouting.$inject = ["$stateProvider", "$urlRouterProvider", "$locationProvider", "$urlMatcherFactoryProvider"];

function appRouting($stateProvider, $urlRouterProvider, $locationProvider, $urlMatcherFactoryProvider) {
  $urlMatcherFactoryProvider.strictMode(false);
  $urlRouterProvider.otherwise("/");
  $locationProvider.html5Mode(true);
  $stateProvider.state(home);
  // $stateProvider.state(tarifas);
  // $stateProvider.state(contacto);
  // $stateProvider.state(centroAyuda);
  // $stateProvider.state(quienesSomos);
  // $stateProvider.state(legales);
  // $stateProvider.state(legalesDetalle);
}

export { appRouting };
