// app.states.js

"use strict";

const home = {
    name: "home",
    url: "/",
    component: "home",
    lazyLoad: ($transition$) => {
      const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
      return import(/* webpackChunkName: "home.module" */ "./components/home/home.module")
        .then(mod => $ocLazyLoad.inject(mod.HOME_MODULE))
        .catch(err => {
          throw new Error("Ooops, something went wrong, " + err);
        });
    },
    onEnter: ["$document", ($document) => {
      $document[0].title = "Ahorra con Energía El Corte Inglés";
      $document[0].querySelector('meta[name="description"]').setAttribute('content', 'Unete a Energia El Corte Ingles y tendras el precio y la atencion de calidad que te mereces con todas las garantias del Grupo El Corte Ingles');
      $document[0].querySelector('meta[name="keyword"]').setAttribute('content', 'Energia El Corte Ingles, Luz  El Corte Ingles, Electrica el corte ingles, Electricidad el corte ingles, Luz el corte ingles, Cambiar compañia luz, Electricas');
      $document[0].querySelector('link[rel="canonical"]').setAttribute('href', 'https://ahorro.energiaelcorteingles.es/');
      $document[0].querySelector('script[type="application/ld+json"]').innerText = '{"@context": "https://schema.org","@graph":[{"@type": "Corporation","@id": "https://energiaelcorteingles.es","legalName": "","description": "","url": "https://energiaelcorteingles.es","address":{"@type": "PostalAddress","addressLocality": "Spain","addressCountry": "ES","postalCode": "","streetAddress": ""},"logo": "","sameAs":["https://www.facebook.com/energiaelcorteingles","https://twitter.com/energiaelcorteingles","https://www.instagram.com/energiaelcorteingles","https://www.youtube.com/channel/UCbi7cIi_LUZkDxOx9CvbpDg","https://www.linkedin.com/company/energiaelcorteingles"]},{"@type": "WebSite","name": "Unete a Energia El Corte Ingles y tendras el precio y la atencion de calidad que te mereces con todas las garantias del Grupo El Corte Ingles","url": "https://energiaelcorteingles.es"}]}';
    }]
};

export { home };
